var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

!function () {
  if ("undefined" != typeof Prism && "undefined" != typeof document) {
    var e = [],
        t = {},
        n = function () {};

    Prism.plugins.toolbar = {};

    var a = Prism.plugins.toolbar.registerButton = function (n, a) {
      var r;
      r = "function" == typeof a ? a : function (e) {
        var t;
        return "function" == typeof a.onClick ? ((t = document.createElement("button")).type = "button", t.addEventListener("click", function () {
          a.onClick.call(this || _global, e);
        })) : "string" == typeof a.url ? (t = document.createElement("a")).href = a.url : t = document.createElement("span"), a.className && t.classList.add(a.className), t.textContent = a.text, t;
      }, n in t ? console.warn("There is a button with the key \"" + n + "\" registered already.") : e.push(t[n] = r);
    },
        r = Prism.plugins.toolbar.hook = function (a) {
      var r = a.element.parentNode;

      if (r && /pre/i.test(r.nodeName) && !r.parentNode.classList.contains("code-toolbar")) {
        var o = document.createElement("div");
        o.classList.add("code-toolbar"), r.parentNode.insertBefore(o, r), o.appendChild(r);
        var i = document.createElement("div");
        i.classList.add("toolbar");

        var l = e,
            d = function (e) {
          for (; e;) {
            var t = e.getAttribute("data-toolbar-order");
            if (null != t) return (t = t.trim()).length ? t.split(/\s*,\s*/g) : [];
            e = e.parentElement;
          }
        }(a.element);

        d && (l = d.map(function (e) {
          return t[e] || n;
        })), l.forEach(function (e) {
          var t = e(a);

          if (t) {
            var n = document.createElement("div");
            n.classList.add("toolbar-item"), n.appendChild(t), i.appendChild(n);
          }
        }), o.appendChild(i);
      }
    };

    a("label", function (e) {
      var t = e.element.parentNode;

      if (t && /pre/i.test(t.nodeName) && t.hasAttribute("data-label")) {
        var n,
            a,
            r = t.getAttribute("data-label");

        try {
          a = document.querySelector("template#" + r);
        } catch (e) {}

        return a ? n = a.content : (t.hasAttribute("data-url") ? (n = document.createElement("a")).href = t.getAttribute("data-url") : n = document.createElement("span"), n.textContent = r), n;
      }
    }), Prism.hooks.add("complete", r);
  }
}();
export default {};